import React from 'react';

import Layout from '../components/Layout';
import OpenSourceList from '../components/OpenSourceList';
import { graphql } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';
import PropTypes from 'prop-types';
import useSiteMetadata from '../components/SiteMetadata';
import Helmet from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export const OpenSourcePageTemplate = ({
  title,
  content,
  contentComponent,
  seo,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="main open-source-page">
      <section className="hero">
        <div className="hero-text">
          <h1 className="page-title-with-description">{title}</h1>
        </div>
      </section>
      <section>
        {seo || ''}
        <PageContent className="content paragraph-white" content={content} />
        <h2 className="sr-only">List of open source projects</h2>
        <OpenSourceList />
      </section>
    </div>
  );
};

OpenSourcePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  seo: PropTypes.object,
};

const OpenSourcePage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { title } = useSiteMetadata();

  return (
    <Layout pageType="PageWithIntro">
      <Helmet title={`Open source | ${title}`} />
      <OpenSourcePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        seo={
          <GatsbySeo
            title={post.frontmatter.title}
            titleTemplate={`%s | ${title}`}
            description={post.frontmatter.teaser}
            canonical={post.frontmatter.canonical}
            openGraph={{
              title: `${post.frontmatter.title}`,
              description: `${post.frontmatter.teaser}`,
              url: `${process.env.PAGE_DOMAIN}${post.fields.slug}`,
            }}
          />
        }
      />
    </Layout>
  );
};

OpenSourcePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default OpenSourcePage;

export const aboutPageQuery = graphql`
  query OpenSourcePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        teaser
      }
      fields {
        slug
      }
    }
  }
`;
